// extracted by mini-css-extract-plugin
export var column = "FAQColocation__column__vMNwf";
export var flex = "FAQColocation__flex__lHIv4";
export var flexColumn = "FAQColocation__flexColumn__r1Esr";
export var gap1 = "FAQColocation__gap1__d_XjL";
export var gap2 = "FAQColocation__gap2__YI0fX";
export var gap3 = "FAQColocation__gap3__Bivt1";
export var gap4 = "FAQColocation__gap4__dP8fb";
export var gap5 = "FAQColocation__gap5__XL4pA";
export var heading = "FAQColocation__heading__kRYEN";
export var row = "FAQColocation__row__HkAON";
export var wrapper = "FAQColocation__wrapper__jfJbP";