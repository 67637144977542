// extracted by mini-css-extract-plugin
export var column = "SubNavigation__column__nEIoh";
export var flex = "SubNavigation__flex__IhkLY";
export var flexColumn = "SubNavigation__flexColumn__H6P3m";
export var gap1 = "SubNavigation__gap1__ToDGg";
export var gap2 = "SubNavigation__gap2__SxUfj";
export var gap3 = "SubNavigation__gap3__OfkwQ";
export var gap4 = "SubNavigation__gap4__ACPCl";
export var gap5 = "SubNavigation__gap5__dqeiq";
export var link = "SubNavigation__link__A4OoM";
export var row = "SubNavigation__row__K01gS";
export var wrapper = "SubNavigation__wrapper__SaJy6";
export var wrapperEnd = "SubNavigation__wrapperEnd__xgQc5";