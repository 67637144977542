// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../Paragraph/P.res.js";
import * as H2 from "../Heading/H2.res.js";
import * as H3 from "../Heading/H3.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BannerReasonsScss from "./BannerReasons.scss";

var css = BannerReasonsScss;

function BannerReasons(props) {
  var __description = props.description;
  var __subtitle = props.subtitle;
  var subtitle = __subtitle !== undefined ? __subtitle : "";
  var description = __description !== undefined ? __description : "";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H3.make, {
                      className: css.subtitle,
                      children: subtitle
                    }),
                JsxRuntime.jsx(H2.make, {
                      className: css.title,
                      children: props.title
                    }),
                JsxRuntime.jsx(P.make, {
                      children: description
                    }),
                JsxRuntime.jsx("div", {
                      children: props.children,
                      className: css.reasonsRow
                    })
              ],
              className: css.root
            });
}

var make = BannerReasons;

export {
  css ,
  make ,
}
/* css Not a pure module */
