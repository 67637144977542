// extracted by mini-css-extract-plugin
export var bannerSection = "BannerLearnMore__bannerSection__Zsmau";
export var bannerWrapper = "BannerLearnMore__bannerWrapper__CtNGj";
export var bannerWrapperBase = "BannerLearnMore__bannerWrapperBase__UrWcP";
export var bannerWrapperReversed = "BannerLearnMore__bannerWrapperReversed__ofeW1";
export var column = "BannerLearnMore__column__d_4ww";
export var container = "BannerLearnMore__container__TwN2b";
export var containerGutter = "BannerLearnMore__containerGutter__Hm3vG";
export var description = "BannerLearnMore__description__G1L2U";
export var flex = "BannerLearnMore__flex__modsw";
export var flexColumn = "BannerLearnMore__flexColumn__y8WRV";
export var gap1 = "BannerLearnMore__gap1__Ezj7R";
export var gap2 = "BannerLearnMore__gap2__ME6Fq";
export var gap3 = "BannerLearnMore__gap3__Jjcdz";
export var gap4 = "BannerLearnMore__gap4__YeRDI";
export var gap5 = "BannerLearnMore__gap5__Jsbcb";
export var imageLeft = "BannerLearnMore__imageLeft__cNpyF";
export var imageRight = "BannerLearnMore__imageRight__fzJ2_";
export var marginButtom0 = "BannerLearnMore__marginButtom0__bS3FH";
export var marginHorizontal0 = "BannerLearnMore__marginHorizontal0__Aj9LS";
export var marginTop0 = "BannerLearnMore__marginTop0__F41Tz";
export var paddingHorizontal0 = "BannerLearnMore__paddingHorizontal0__SlTuU";
export var row = "BannerLearnMore__row__Ms5nV";
export var subtitle = "BannerLearnMore__subtitle__vlH0P";
export var svgIllustration = "BannerLearnMore__svgIllustration__P8hAi";
export var textLeft = "BannerLearnMore__textLeft__mTms5";
export var textRight = "BannerLearnMore__textRight__kinlN";
export var title = "BannerLearnMore__title__S19E8";