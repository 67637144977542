// extracted by mini-css-extract-plugin
export var column = "LearnMoreSectionColocation__column__jYNsB";
export var ctaButtonsRow = "LearnMoreSectionColocation__ctaButtonsRow__s9S8r";
export var flex = "LearnMoreSectionColocation__flex__W4X4E";
export var flexColumn = "LearnMoreSectionColocation__flexColumn__w5afK";
export var gap1 = "LearnMoreSectionColocation__gap1__GXRwK";
export var gap2 = "LearnMoreSectionColocation__gap2__XSlJo";
export var gap3 = "LearnMoreSectionColocation__gap3__YeNAE";
export var gap4 = "LearnMoreSectionColocation__gap4__tn5Or";
export var gap5 = "LearnMoreSectionColocation__gap5__pOjlR";
export var row = "LearnMoreSectionColocation__row__YEQQz";
export var wrapper = "LearnMoreSectionColocation__wrapper__LXXPr";