// extracted by mini-css-extract-plugin
export var card = "DirectoryColocation__card__jPrk4";
export var cardsRow = "DirectoryColocation__cardsRow__A__Hi";
export var column = "DirectoryColocation__column__PdcZt";
export var description = "DirectoryColocation__description__TulY0";
export var flex = "DirectoryColocation__flex__L9Iln";
export var flexColumn = "DirectoryColocation__flexColumn__JxYWY";
export var gap1 = "DirectoryColocation__gap1__Etiak";
export var gap2 = "DirectoryColocation__gap2__enubC";
export var gap3 = "DirectoryColocation__gap3__e4XGF";
export var gap4 = "DirectoryColocation__gap4__deMIT";
export var gap5 = "DirectoryColocation__gap5__qrXOO";
export var heading = "DirectoryColocation__heading__Fv66g";
export var iconComponent = "DirectoryColocation__iconComponent__EoR2T";
export var mainHeading = "DirectoryColocation__mainHeading__TJdcb";
export var row = "DirectoryColocation__row__M2jEj";