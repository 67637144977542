// extracted by mini-css-extract-plugin
export var buttonsRow = "JumbotronColocation__buttonsRow__Z_b7O";
export var column = "JumbotronColocation__column__MNvwI";
export var flex = "JumbotronColocation__flex__qER10";
export var flexColumn = "JumbotronColocation__flexColumn__lqdet";
export var gap1 = "JumbotronColocation__gap1__P8mAc";
export var gap2 = "JumbotronColocation__gap2__x3R4D";
export var gap3 = "JumbotronColocation__gap3__eec6H";
export var gap4 = "JumbotronColocation__gap4__OFvh3";
export var gap5 = "JumbotronColocation__gap5__Tf4PH";
export var heading = "JumbotronColocation__heading___mmOU";
export var headingExplainer = "JumbotronColocation__headingExplainer__VxE9M";
export var row = "JumbotronColocation__row__KCQ7T";
export var wrapper = "JumbotronColocation__wrapper__J29tU";