// extracted by mini-css-extract-plugin
export var column = "BannerProviderIcons__column__kFERR";
export var flex = "BannerProviderIcons__flex__eYId7";
export var flexColumn = "BannerProviderIcons__flexColumn__vRB4R";
export var gap1 = "BannerProviderIcons__gap1__m_RCU";
export var gap2 = "BannerProviderIcons__gap2__tJAmu";
export var gap3 = "BannerProviderIcons__gap3__SMIYR";
export var gap4 = "BannerProviderIcons__gap4__cV6VC";
export var gap5 = "BannerProviderIcons__gap5__qjeFN";
export var icon = "BannerProviderIcons__icon__kQQqx";
export var row = "BannerProviderIcons__row__KURmG";
export var wrapper = "BannerProviderIcons__wrapper__MnshY";