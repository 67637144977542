// extracted by mini-css-extract-plugin
export var anchor = "Colocation__anchor__c6VSX";
export var backgroundImage = "Colocation__backgroundImage__I8OBl";
export var colocationDirectorySection = "Colocation__colocationDirectorySection__b8VtT";
export var column = "Colocation__column__xWi7d";
export var faqSection = "Colocation__faqSection__DjvMv";
export var flex = "Colocation__flex__DW1nl";
export var flexColumn = "Colocation__flexColumn__aIOf3";
export var gap1 = "Colocation__gap1__A_Thn";
export var gap2 = "Colocation__gap2__u3iqk";
export var gap3 = "Colocation__gap3__BSHdp";
export var gap4 = "Colocation__gap4__a7zLx";
export var gap5 = "Colocation__gap5__WBpeH";
export var jumbotronSection = "Colocation__jumbotronSection__rru5e";
export var learnMoreColocationSection = "Colocation__learnMoreColocationSection__imvtP";
export var providerIconsSection = "Colocation__providerIconsSection__cxZzg";
export var row = "Colocation__row__dmwaG";
export var subNavigationColocationSection = "Colocation__subNavigationColocationSection__vuKXg";
export var whyColocationSection = "Colocation__whyColocationSection__VmJeK";
export var wideContainer = "Colocation__wideContainer__sR8Km";
export var wrapper = "Colocation__wrapper__R2OLk";