// extracted by mini-css-extract-plugin
export var column = "BannerSingleReason__column__JhF4K";
export var flex = "BannerSingleReason__flex__hqe8I";
export var flexColumn = "BannerSingleReason__flexColumn__cU1wv";
export var gap1 = "BannerSingleReason__gap1__AwsD8";
export var gap2 = "BannerSingleReason__gap2__jUtLR";
export var gap3 = "BannerSingleReason__gap3__JgvwZ";
export var gap4 = "BannerSingleReason__gap4__hpO0I";
export var gap5 = "BannerSingleReason__gap5__AzxIL";
export var reason = "BannerSingleReason__reason__GhNLi";
export var reasonDescription = "BannerSingleReason__reasonDescription__rgqop";
export var reasonTitle = "BannerSingleReason__reasonTitle__F1GLZ";
export var row = "BannerSingleReason__row__Y7oPM";
export var svgIcon = "BannerSingleReason__svgIcon__Lq8rC";